import React, { useState, useEffect } from "react"
import GitexPopupModal from "../../../core/GitexPopupModal"
import Calender from "../../../../assets/images/store/Calendar.svg"
import Location from "../../../../assets/images/store/Location.svg"
const CES = () => {
  const [openGitex, setOpenGitex] = useState(false)

  const openGitexModal = () => {
    setOpenGitex(true)
  }
  const closeGitexModal = () => {
    setOpenGitex(false)
  }

  // Target date (CES 2025)
  const targetDate = new Date("January 07, 2025 22:30:00").getTime()

  const calculateTimeLeft = () => {
    const now = new Date().getTime()
    const timeDifference = targetDate - now

    if (timeDifference > 0) {
      return {
        days: Math.floor(timeDifference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((timeDifference % (1000 * 60)) / 1000),
      }
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }
    }
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    // Cleanup the interval on component unmount
    return () => clearInterval(timer)
  }, [])

  return (
    <>
      {openGitex && (
        <GitexPopupModal
          open={openGitex}
          closeGitexModal={closeGitexModal}
          url={"https://calendly.com/webmob/meet-us-at-ces-2025"}
        />
      )}

      <div
        className="min-h-screen flex items-center justify-center text-white bg-cover bg-center bg-gradient-to-br from-blue-900 via-teal-900 to-green-900 bg-blend-overlay"
        style={{
          backgroundImage: `url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/CES_Hero_Section_Image.webp")`,
        }}
      >
        <div className="p-4 rounded-lg max-w-4xl w-full backdrop-blur-sm">
          <h1 className="text-3xl md:text-6xl font-bold my-4 md:mb-4 text-center leading-snug md:leading-tight">
            Shape the Future with
            <br />
            WebMobTech at{" "}
            <span
              className="bg-gradient-to-r from-cyan-300 to-green-400 text-transparent bg-clip-text font-extrabold"
              style={{
                backgroundImage:
                  "linear-gradient(90deg, #00A0DC 60.21%, #DEDF1F 88.1%)",
              }}
            >
              CES 2025
            </span>
          </h1>

          <p className="text-xl font-medium text-center">
            <span className="block">
              {" "}
              Discover groundbreaking innovations, and seize new business
              opportunities.
            </span>{" "}
            Join us in shaping tomorrow's technologies and unlocking the
            potential of AI and beyond
          </p>
          <div className="flex justify-center lg:justify-center w-full">
            <button
              className="my-4 md:my-8 lg:mt-6 bg-yellow-500 relative px-8 py-3 text-base leading-6 font-semibold text-shark-500  hover:bg-yellow-600 rounded"
              onClick={openGitexModal} // remove after gitex2022
            >
              Schedule a Meeting
            </button>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-4 mb-6">
            <div className="flex items-center">
              <span className="text-xl mr-2">
                <img src={Calender} width="16"></img>
              </span>
              <span>07-10 January, 2025</span>
            </div>
            <div className="flex items-center">
              <span className="text-xl mr-2">
                <img src={Location} width="16"></img>
              </span>
              <span>Booth 9542, North Hall, LVCC</span>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-center p-4 md:max-w-xl w-full">
              <div className="bg-black bg-opacity-50 p-4 md:p-8 rounded-lg border border-yellow-400 aspect-square flex flex-col justify-center">
                <div className="text-3xl font-bold text-yellow-400">
                  {timeLeft.days}
                </div>
                <div className="text-sm text-white mt-2">Days</div>
              </div>
              <div className="bg-black bg-opacity-50  p-4 md:p-8 rounded-lg border border-yellow-400 aspect-square flex flex-col justify-center">
                <div className="text-3xl font-bold text-yellow-400">
                  {timeLeft.hours}
                </div>
                <div className="text-sm text-white mt-2">Hours</div>
              </div>
              <div className="bg-black bg-opacity-50  p-4 md:p-8 rounded-lg border border-yellow-400 aspect-square flex flex-col justify-center">
                <div className="text-3xl font-bold text-yellow-400">
                  {timeLeft.minutes}
                </div>
                <div className="text-sm text-white mt-2">Minutes</div>
              </div>
              <div className="bg-black bg-opacity-50  p-4 md:p-8 rounded-lg border border-yellow-400 aspect-square flex flex-col justify-center">
                <div className="text-3xl font-bold text-yellow-400">
                  {timeLeft.seconds}
                </div>
                <div className="text-sm text-white mt-2">Seconds</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CES
