export const ComponentsMap = new Map([
  ["acf/right-media-left-content", "RightMediaLeftContentBlock"],
  ["acf/cta-block", "CTABlock"],
  ["acf/left-media-right-content", "LeftMediaRightContentBlock"],
  ["acf/work-card", "WorkCardBlock"],
  ["acf/center-media-center-content", "CenterMediaContentBlock"],
  ["acf/faq", "FAQ"],
  ["acf/spacer", "SpacerBlock"],
  ["acf/appstore", "AppStoreBlock"],
  ["core/shortcode", "ShortCode"],
  ["acf/title-subtitle", "TitleSubtitleBlock"],
  ["acf/masonry-photo-grid", "LifeAtWebmob"],
  ["acf/title-subtitle-slider", "TitleSubtitleSliderBlock"],
  ["acf/tech-stack-cards", "TechStackCardBlock"],
  ["acf/card-grid-bg-img", "CardGridBGImageBlock"],
  ["acf/work-portfolio-slider", "WorkPortfolioSlider"],
  ["acf/three-grid-features", "ThreeGridFeaturesBlock"],
  ["acf/timeline-slider", "TimeLine"],
  ["acf/video-links", "VideoLinks"],
  ["acf/color-component", "ColorComponent"],
  ["acf/my-teams", "MyTeams"],
  ["acf/hiring-model", "HiringModelBlock"],
  ["acf/vertical-tab","VerticalTabs"],
  ['acf/our-work','OurWork'],
  ["acf/awards","AwardsSlider"],
  ["acf/video-testimonials","VideoTestimonials"],
  ["acf/image-testimonials","ImageTestimonials"],
  ["acf/all-logo","Logos"],
  ["acf/tech-tab-stack","TechTabStackBlock"],
  ["acf/testimonials","Testimonials"],
  ["acf/common-statistics","Statistics"],
  ["acf/technology-tools","TechnologyTools"]
])
