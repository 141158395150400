import React, { useState } from "react";

const CESVideoPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <div className="bg-white">
      <div className="px-4 sm:px-6 max-w-4xl mx-auto lg:px-12 bg-white pb-8 md:pb-16 lg:pb-20 xl:pb-20">
        {!isPlaying && (
          <div
            className="relative cursor-pointer"
            onClick={() => setIsPlaying(true)}
          >
            {/* YouTube thumbnail */}
            <img
              src="https://img.youtube.com/vi/9Z1UvkpaHP4/maxresdefault.jpg"
              alt="YouTube video thumbnail"
              className="w-full h-[450px] sm:h-[350px] md:h-[450px] object-cover rounded-2xl"
            />
            {/* Play button overlay */}
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 rounded-2xl">
              <img
                src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/05/Play-Button.png"
                alt="Play button"
                className="w-16 h-16"
              />
            </div>
          </div>
        )}

        {isPlaying && (
          <div className="relative w-full overflow-hidden" style={{ paddingTop: "56.25%" }}>
            <iframe
              className="absolute inset-0 w-full h-full rounded-2xl"
              src="https://www.youtube.com/embed/9Z1UvkpaHP4?autoplay=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        )}
      </div>
    </div>
  );
};

export default CESVideoPlayer;
